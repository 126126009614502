import React, { useState } from "react"
import { AppBar, Button, NewYearGarland, theme, ToolBar } from "kui-basic"
import styled from "@emotion/styled"
import { useNavigate } from "react-router"
import { NewYearLogo } from "kui-icon"
import { useWindowWidth } from "kui-utils"
import HideMenuContent from "./HideMenuContent"
import { headerRoutes } from "../../../routes/headerRoutes"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const width = useWindowWidth()

  const goToMain = () => {
    navigate("/")
  }

  return (
    <StyledAppBar>
      <StyledToolBar>
        <HideMenuContent
          links={headerRoutes}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <StyledButton size="xs" variant="transparent" onClick={goToMain}>
          <NewYearLogo />
        </StyledButton>
      </StyledToolBar>

      <NewYearGarland width={width} />
    </StyledAppBar>
  )
}

export default Header

const StyledButton = styled(Button)`
  padding: 0 !important;
  margin-left: calc(50% - 32px - 45px);
`

const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  z-index: 1000;
  background: white;
  border-bottom: 1px solid ${theme.palette.background.light3};
`

const StyledToolBar = styled(ToolBar)`
  width: 100%;
  padding: 0 16px;
`
