import React from "react"
import styled from "@emotion/styled"
import { Button, Container, Grid, Heading } from "kui-basic"
import { PrevArrowIcon } from "kui-icon"
import { useNavigate } from "react-router-dom"
import Page from "../../../../components/common/Page"
import NewYearBunny from "../../../../components/icons/NewYearBunny"

const InspectionSuccessScreen = () => {
  const navigate = useNavigate()

  const goToMain = () => {
    navigate("/")
  }

  return (
    <Page>
      <StyledContainer>
        <StyledWrapper container direction="column" alignItems="center">
          <Grid container alignItems="center" justify="center">
            <NewYearBunny width={120} height="auto" />
          </Grid>
          <StyledTitle size="h2" color="fiftyP">
            Инспекция успешно создана!
          </StyledTitle>
        </StyledWrapper>
        <Button
          variant="whiteWithGray"
          size="s"
          startIcon={<PrevArrowIcon />}
          onClick={goToMain}
        >
          Вернуться к списку квартир
        </Button>
      </StyledContainer>
    </Page>
  )
}

export default InspectionSuccessScreen

const StyledWrapper = styled(Grid)`
  margin-bottom: 32px;
`

const StyledContainer = styled(Container)`
  margin: auto;
  height: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledTitle = styled(Heading)`
  text-align: center;
  margin-top: 16px;
`
